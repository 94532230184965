"use strict";

module.exports = {
  url: "https://ronit-mathur.netlify.app/",
  pathPrefix: "/pages/about",
  title: "Ronit Mathur",
  subtitle: "",
  copyright: "© All rights reserved.",
  disqusShortname: "",
  postsPerPage: 4,
  googleAnalyticsId: "UA-73379983-2",
  useKatex: false,
  darkFunctionalityIsOn: true,
  menu: [
    {
      label: "About me",
      path: "/pages/about",
    },
    {
      label: "Articles",
      path: "/",
    },
    {
      label: "Contact me",
      path: "/pages/contacts",
    },
  ],
  author: {
    name: "Ronit Mathur",
    photo: "/RonitProfilePicture.jpg",
    bio: "",
    contacts: {
      email: "ronitmathur19@gmail.com",
      facebook: "#",
      telegram: "#",
      twitter: "#",
      github: "https://github.com/Ronit-Mathur",
      rss: "",
      vkontakte: "",
      linkedin: "https://www.linkedin.com/in/ronitmathur/",
      instagram: "#",
      line: "",
      gitlab: "",
      weibo: "",
      codepen: "",
      youtube: "",
      soundcloud: "",
      medium: "",
    },
  },
};
